import { forwardRef, useState } from 'react';
import { Input, InputProps } from './input.tsx';
import { EyeClosedIcon } from '../icons/react/eye-closed.tsx';
import { EyeIcon } from '../icons/react/eye.tsx';
import { eyeButton, passwordInputContainer } from './password-input.css.ts';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  function PasswordInputComponent({ onChange, ...props }, ref) {
    const [passwordShown, setPasswordShown] = useState(false);
    const [showEye, setShowEye] = useState(
      !!props.value || !!props.defaultValue,
    );

    return (
      <Input
        ref={ref}
        {...props}
        type={passwordShown ? 'text' : 'password'}
        onChange={(e) => {
          setShowEye(e.target.value.length > 0);
          onChange?.(e);
        }}
        className={passwordInputContainer}
        after={
          showEye ? (
            <button
              type={'button'}
              className={eyeButton}
              onClick={(e) => {
                e.preventDefault();
                setPasswordShown((prev) => !prev);
              }}
            >
              {passwordShown ? (
                <EyeClosedIcon width={20} height={20} />
              ) : (
                <EyeIcon width={20} height={20} />
              )}
            </button>
          ) : null
        }
      />
    );
  },
);
